<template>
  <div>
    <div class="view_link_icon_area ignore-pdf">
      <a role="link" @click="openModal('share')" v-if="noShared">
        <img src="@/assets/images/icon_view_share.png" alt="">
      </a>

      <a role="link" @click="openModal('print')" v-if="noShared">
        <img src="@/assets/images/icon_view_print.png" alt="">
      </a>

      <a role="link" @click="openModal('pdf')" v-if="noShared">
        <img src="@/assets/images/icon_view_pdf.png" alt="">
      </a>

      <a role="link" @click="openModal('qr')">
        <img src="@/assets/images/icon_view_qr.png" alt="">
      </a>

      <a role="link" @click="viewBookmark" v-if="bookmarkId">
        <img src="@/assets/images/icon_view_bookmark.png" alt="">
      </a>
    </div>

    <!-- Modal -->
    <div class="modal" v-if="showModal">
      <!-- Modal의 내용 -->
      <div class="modal_contents">
        <div class="modal_close close">
          <a @click="closeModal">close</a>
        </div>

        <p class="modal_exp" v-if="this.modalType === 'share'">
          본 콘텐츠의 바로가기 링크 주소는<br>
          아래와 같습니다.<br>
          아래 주소를 복사하셔서 공유하실 수 있습니다.
        </p>
        <p class="modal_exp" v-else-if="this.modalType === 'print'">
          선생님 본 콘텐츠를<br>
          프린트 하시려고 하시나요?<br><br>
          아래 프린트하기 버튼을 클릭하시면<br>
          연결된 프린터로 출력하실 수 있습니다.
        </p>
        <p class="modal_exp" v-else-if="this.modalType === 'pdf'">
          선생님 본 콘텐츠를<br>
          PDF로 추출하시려고 하시나요?<br><br>
          아래 PDF추출하기 버튼을 클릭하시면<br>
          PDF로 추출하실 수 있습니다.
        </p>
        <p class="modal_exp" v-else-if="this.modalType === 'qr'">
          본 콘텐츠의 바로가기 QR코드는<br>
          아래와 같습니다.<br>
          아래 주소를 복사하셔서 공유하실 수 있습니다.
        </p>
        <p class="modal_exp" v-else-if="this.modalType === 'bookmarkEnable'">
          선생님께서는 본 콘텐츠를<br>
          북마크 하셨습니다.<br><br>
          북마크하신 콘텐츠는 My Page > 관심 콘텐<br>
          츠에서 언제든지 조회하실 수 있습니다.
        </p>
        <p class="modal_exp" v-else-if="this.modalType === 'bookmarkDisable'">
          선생님께서는 본 콘텐츠를<br>
          북마크 해제하셨습니다.<br><br>
          북마크하신 콘텐츠는 My Page > 관심 콘텐<br>
          츠에서 언제든지 조회하실 수 있습니다.
        </p>

        <input class="modal_input obShareUrl" type="text" :value="obShareUrl" readonly v-if="modalType === 'share'">
        <vue-qrcode :value="obShareUrl" :options="{ width: 200 }" v-if="modalType === 'qr'" id="cnvs"></vue-qrcode>

        <div class="modal_btn" v-if="this.modalType === 'share'">
          <span class="btn_modal" @click="copyUrlToClipboard">복사하기</span>
          <span class="btn_modal2" @click="closeModal">확인</span>
        </div>
        <div class="modal_btn" v-else-if="this.modalType === 'print'">
          <span class="btn_modal" @click="printPage">프린트하기</span>
          <span class="btn_modal2" @click="closeModal">확인</span>
        </div>
        <div class="modal_btn" v-else-if="this.modalType === 'pdf'">
          <span class="btn_modal" @click="pdfDownload">PDF추출하기</span>
          <span class="btn_modal2" @click="closeModal">확인</span>
        </div>
        <div class="modal_btn" v-else-if="this.modalType === 'qr'" style="margin-top: 5%;">
          <span class="btn_modal" @click="downloadQr">다운로드</span>
          <span class="btn_modal2" @click="closeModal">확인</span>
        </div>
        <div class="modal_btn" v-else-if="this.modalType === 'bookmarkEnable' || this.modalType === 'bookmarkDisable'">
          <span class="btn_modal" @click="closeModal('/my-page/interest-therapeutic-area')">관심콘텐츠 보기</span>
          <span class="btn_modal2" @click="closeModal">확인</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import html2pdf from "html2pdf.js";
import sendReportAPI from "@/api/sendReportAPI";


export default {
  name: "SharedArea",
  components: {
    VueQrcode
  },
  props: {
    fileName: {
      type: String,
      required: true
    },
    bookmarkId: {
      type: [Number, String],
    },
    noShared: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showModal: false,
      modalType: "",
      obShareUrl: "",
      saveDisplayType: {},
    }
  },
  created() {
    this.obShareUrl = window.document.location.href;
  },
  methods: {
    openModal(modalType) {
      this.modalType = modalType;
      this.showModal = true;
      document.documentElement.classList.add("overflow-y-hidden");
    },
    closeModal() {
      document.documentElement.classList.remove("overflow-y-hidden");
      this.showModal = false;
      for (let i = 0; i < arguments.length; i++) {
        if (typeof (arguments[i]) == 'string') {
          this.$router.push(arguments[i])
        }
      }
    },
    copyUrlToClipboard() {
      const obShareUrl = document.getElementsByClassName("obShareUrl");
      obShareUrl[0].select();
      obShareUrl[0].setSelectionRange(0, 9999);
      document.execCommand("copy");
      obShareUrl[0].setSelectionRange(0, 0);
      // alert("클립보드에 복사되었습니다.");
    },
    printPage() {
      this.closeModal();
      setTimeout(() => {
        this.printCurrentPage()
      }, 200)
    },
    pdfDownload() {
      this.closeModal();
      html2pdf().set({
        image: {type: "png"},
        margin: 12,
        filename: `${this.fileName}.pdf`,
        pageBreak: {
          // mode: ['avoid-all', 'css'],
          avoid: ['div']
        },
        enableLinks: false,
        html2canvas: {
          useCORS: true,
          letterRendering: true,
          ignoreElements: function (element) {	//pdf에 출력하지 않아야할 dom이 있다면 해당 옵션 사용
            if (element.classList.contains("ignore-pdf")) {
              return true;
            }
          }
        },
        jsPDF: {orientation: 'portrait', unit: 'mm', format: 'a4', compressPDF: true}
      }).from(document.getElementById('print-area')).save()

    },
    downloadQr() {
      let cnvs = document.getElementById('cnvs');
      let dataURL = cnvs.toDataURL('image/png');
      dataURL = dataURL.replace(/^data:image\/[^;]*/, 'data:application/octet-stream');
      dataURL = dataURL.replace(/^data:application\/octet-stream/, 'data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=Canvas.png');
      let aTag = document.createElement('a');
      aTag.download = `${this.fileName}.png`;
      aTag.href = dataURL;
      aTag.click();
    },
    viewBookmark() {
      sendReportAPI.postBookmarkRequest(this.bookmarkId).then(value => {
        const {data} = value
        if (data.code === 200) {
          if (data.value) {
            this.openModal('bookmarkEnable')
          } else {
            this.openModal('bookmarkDisable')
          }
        }
      }).catch(e => {
        console.log(e)
        throw e
      })
      // alert("viewBookmark");
    },
    printCurrentPage() {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf("msie") !== -1) {    //익스플로러인지 체크
        window.print()
      } else {
        Array.from(document.querySelectorAll('.ignore-pdf')).forEach((element) => {
          this.saveDisplayType = {
            ...this.saveDisplayType,
            [element]: element.style.display
          }
          element.style.display = 'none'
        })
        this.$htmlToPaper('print-area',
            {
              name: '_blank',
              specs: [
                'fullscreen=yes',
                'titlebar=yes',
                'scrollbars=yes'
              ],
              styles: [
                'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                'https://unpkg.com/kidlat-css/css/kidlat.css',
                '/css/style.css'
              ],
              "timeout": 1000,
              "autoClose": true,
              "windowTitle": this.fileName
            }, () => {
              Array.from(document.querySelectorAll('.ignore-pdf')).forEach((element) => {
                element.style.display = this.saveDisplayType[element]
              })
            });
      }

    },
  },
}
</script>

<style scoped>
.modal_input {
  border: 3px solid #BE2BBB;
  margin-bottom: 10%;
  padding: 10px 20px;
  width: 87%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn_modal2 {
  background-color: #7F7F7F;
  border-radius: 3px;
  width: 180px;
  height: 47px;
  display: block;
  margin: 0 auto;
  color: #fff;
  line-height: 47px;
}

.btn_modal2:hover {
  background-color: #fff;
  border: 3px solid #7F7F7F;
  color: #7F7F7F;
  line-height: 41px;
}
</style>
