import axiosInstance from '@/plugins/proxyHandler'

export default {
    async postAbnormalReport(report) {
        try {
            return await axiosInstance.post(`/api/v1/ar`, {
                ...report
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },

    async postMedicalRequest(request) {
        try {
            return await axiosInstance.post(`/api/v1/mr`, {
                ...request
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async postVisitRequest(request) {
        try {
            return await axiosInstance.post(`/api/v1/vr`, {
                ...request
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async postBookmarkRequest(id) {
        try {
            return await axiosInstance.post(`/api/v1/bookmark/${id}`)
        } catch (e) {
            console.log(e)
            throw e
        }
    }
};
